
::v-deep .v-input__slot {
  background: transparent !important;
  border-color: transparent !important;
}

.scb-easy-modal
  background: transparent linear-gradient(180deg, #A700FF 0%, #5500B9 100%) 0% 0% no-repeat padding-box;
.scb-card-qrcode
  background white
  border-radius: 8px;
  border: 1px solid #00000000;
  padding: 1.5rem
.button-back-scb
  border-color: white !important

.is-desktop
  display: block
  @media tablet
    display: none
.note-form-address
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  margin-top: -32px

  font-size: 0.875rem

  > div {
    text-align: center
  }

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px



.warning-tax-invoice
  width: 100%
  padding: 12px
  // border: 1px solid #ccc
  background: #f2f2f2;
  border-radius: 8px
  font-size: 14px
  margin-top: 16px

.warning-tax-invoice-title
  font-size: 15px
  font-weight: bold

.text-alert
  color: $color-red

.text-black
  color: $color-bnn-gray-medium

.form-customer-layout
    display: flex
    flex-direction: column
    gap: 26px

::v-deep {

    .input-compact-container {
        &:first-child {
            .input-compact {
                border-radius: 5px 0 0 5px !important;

                &:not(.v-input--is-focused) {
                    fieldset {
                        border-right: none !important;
                    }
                }
            }


        }
        &:last-child {
            .input-compact {
                border-radius: 0 5px 5px 0 !important;
            }
        }
    }

    .no-border-shadow .v-input__control {
        border: none !important;
        box-shadow: none !important;
    }
}

.action-confirm-order-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    .ship-date {
        width 100%
        text-align: center;
        border: 1px solid $color-gray85;
        border-radius: 8px;
        padding: 12px;
    }
}
